<template>
    <div class="m2 wrapper wrapper--default">
        <h3 class="text-center m-2">Opbouw</h3>
        <hr>
        <Loading v-if="loading" />
        <div class="p-2" v-else>
            <div class="flex center">
                <PurchaseDuoTable :table_data="structures" :bu="bu" />
            </div>
        </div>
    </div>
</template>
<script>
import PurchaseDuoTable from '@/components/PurchaseDuoComponents/PurchaseDuoTable.vue';
import Loading from '@/components/Loading.vue';
import request from '@/functions/request.js';

export default {
    props: ['bu', 'kind'],
    components: { PurchaseDuoTable, Loading },
    data: () => ({
        structures: null,
        loading: true
    }),
    created() {
        this.getData(this.bu, this.kind)
    },
    watch: {
        bu(newbu) {
            this.loading = true;
            this.getData(newbu, this.kind)
        }
    },
    methods: {
        getData(bu, kind) {
            request(`purchaseduo/structure/${bu}/${kind}`, "GET").then(({ structures }) => {
                this.structures = structures;
                this.loading = false;
            })
        }
    }
};
</script>